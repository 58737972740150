.Home {

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
    width: 100%;
    gap: 1em;

}